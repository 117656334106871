<template>
  <div id="registration">

    <b-row class="m-b-sm">
      <b-col md>
        <!-- not empty -->
        <div class="b-r-lg">

          <div class="article m">

            <div v-if="!form.client.role">
              <b-row>
                <h3>{{ $t('registration.what_are_you') }}</h3>
              </b-row>
              <b-row class="m-t-xl">
                <b-button class="m-b block full-width" variant="primary" @click.prevent="form.client.role = 3">
                  <font-awesome-icon class="left" icon="users"/>
                  {{ $t('registration.client_role_driver') }}
                </b-button>
              </b-row>
              <b-row>
                <b-button class="m-b block full-width" variant="primary" @click.prevent="form.client.role = 1">
                  <font-awesome-icon icon="house-user"/>
                  {{ $t('registration.client_role_service') }}
                </b-button>
              </b-row>
            </div>

            <!-- form -->
            <b-row v-else-if="showForm">
              <h3>{{ $t('registration.your_contact_data') }}</h3>

              <b-form @submit.prevent="onSubmit">

                <b-row v-for="list in contactInputs">
                  <b-col v-for="input in list" v-bind="$compute(input.col)"
                         v-if="input.visible===undefined || $compute(input.visible)">
                    <generic-input v-bind="input" :value="$g(form, input.key)"
                                   @input="(event) => $s(form, input.key, event)"/>
                  </b-col>
                </b-row>

                <h4>{{ $t('registration.business_data') }}</h4>

                <b-row v-for="list in businessInputs">
                  <b-col v-for="input in list" v-bind="$compute(input.col)"
                         v-if="input.visible===undefined || $compute(input.visible)">
                    <generic-input v-bind="input" :value="$g(form, input.key)"
                                   @input="(event) => $s(form, input.key, event)"/>
                  </b-col>
                </b-row>

                <p>{{ $t('registration.submit_text') }}</p>

                <b-button class="m-b block full-width" type="submit" variant="primary">
                  {{ $t('button.send_data_and_register') }}
                </b-button>

                <p>{{ $t('registration.accepting_terms') }}</p>
              </b-form>
            </b-row>

            <!-- form submitted -->
            <b-row v-else>
              <h3>{{ $t('registration.confirmation_required') }}</h3>
              {{ $t('registration.check_your_mails_for_confirmation') }}
            </b-row>

          </div>

        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import GenericInput from "@/components/generic_input.vue";
import {register} from "@/services/employees";

export default {
  components: {GenericInput},
  data() {
    return {
      contactInputs: [
        [
          {
            key: 'first_name',
            label: 'common.first_name',
            required: true
          },
          {
            key: 'last_name',
            label: 'common.last_name',
            required: true
          }
        ],
        [
          {
            key: 'birthdate',
            label: 'common.birthdate',
            type: 'daypicker',
            required: true
          }
        ],
        [
          {
            key: 'email',
            label: 'common.email',
            type: 'email',
            required: true
          }
        ],
        [
          {
            key: 'phone_number',
            label: 'common.phone_number',
            type: 'tel',
            required: true
          }
        ]
      ],
      businessInputs: [
        [
          {
            key: 'client.name',
            label: 'common.name',
            required: true,
            formatter: (value) => {
              return value.replace(/[^\/\w\s-]/g, '');
            }
          }
        ],
        [
          {
            key: 'client.address.street_name',
            label: 'common.street',
            required: true
          },
          {
            key: 'client.address.street_number',
            label: 'common.street_number',
            required: true
          }
        ],
        [
          {
            key: 'client.address.postcode',
            label: 'common.postcode',
            required: true
          },
          {
            key: 'client.address.city_name',
            label: 'common.residence',
            required: true
          }
        ],
        [
          {
            key: 'client.address.country_name',
            label: 'common.country',
            required: true
          }
        ],
      ],
      showSelectClientRole: true,
      showForm: true,
      form: {
        first_name: null,
        last_name: null,
        birthdate: new Date("1990-01-01"),
        email: null,
        phone_number: null,
        client: {
          role: null,
          name: null,
          address: {
            street_name: null,
            street_number: null,
            postcode: null,
            city_name: null,
            country_name: "Deutschland"
          }
        }
      }
    }
  },
  computed: {

  },
  methods: {
    onSubmit(event) {
      this.form.client.email = this.form.email;
      this.form.client.phone_number = this.form.phone_number;
      register(this.form).then(response => {
        this.showForm = false
      }).catch()
    },
  }
}
</script>

<style scoped lang="scss">
.article {
  max-width: 500px;
}
</style>


